/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-merge-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.75 6v1q.211.451.588.95c.537.716 1.259 1.44 2.016 2.196l-.708.708-.015-.016c-.652-.652-1.33-1.33-1.881-2.015V12h-1.5V6H6.034a.25.25 0 01-.192-.41l1.966-2.36a.25.25 0 01.384 0l1.966 2.36a.25.25 0 01-.192.41z"/><path pid="1" fill-rule="evenodd" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 01.7 0l6.516 6.515a.495.495 0 010 .7L8.35 14.866a.495.495 0 01-.7 0L1.134 8.35a.495.495 0 010-.7L7.65 1.134z"/>',
    },
});
